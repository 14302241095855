import React from 'react';
import { array, bool, node, oneOf, oneOfType, string } from 'prop-types';
import classNames from 'classnames';

import IconInfo from './Icons/IconInfo/IconInfo';
import IconHelp from './Icons/IconHelp/IconHelp';
import IconSuccess from './Icons/IconSuccess/IconSuccess';
import IconAttention from './Icons/IconAttention/IconAttention';

import css from './InfoBox.module.css';

const INFORMATION = 'information';
const ATTENTION = 'attention';
const HELP = 'help';
const SUCCESS = 'success';

const iconConfig = [
  { type: INFORMATION, component: <IconInfo className={css.icon} /> },
  { type: ATTENTION, component: <IconAttention className={css.icon} /> },
  { type: HELP, component: <IconHelp className={css.icon} /> },
  { type: SUCCESS, component: <IconSuccess className={css.icon} /> },
];

const InfoBox = props => {
  const {
    rootClassName,
    className,
    imgClassName,
    imgSrc,
    contentType,
    iconType,
    title,
    text,
    withBorder,
    withBackground,
  } = props;

  const isInformation = contentType === INFORMATION;
  const isAttention = contentType === ATTENTION;
  const isHelp = contentType === HELP;
  const isSuccess = contentType === SUCCESS;

  const icon = iconConfig.find(icon => icon.type === iconType);

  const borderClasses =
    withBorder &&
    classNames(css.withBorder, {
      [css.borderInformation]: isInformation,
      [css.borderAttention]: isAttention,
      [css.borderHelp]: isHelp,
      [css.borderSuccess]: isSuccess,
    });
  const backgroundClasses =
    withBackground &&
    classNames(css.withBackground, {
      [css.backgroundInformation]: isInformation,
      [css.backgroundAttention]: isAttention,
      [css.backgroundHelp]: isHelp,
      [css.backgroundSuccess]: isSuccess,
    });
  const classes = classNames(
    rootClassName || css.root,
    borderClasses,
    backgroundClasses,
    className
  );
  const imgClasses = classNames(css.img, imgClassName);

  return (
    <div className={classes}>
      <div className={css.textWrapper}>
        {imgSrc ? <img className={imgClasses} src={imgSrc} /> : null}
        {title ? <span className={css.title}>{title}</span> : null}
        <span className={css.text}>{text}</span>
      </div>
      {iconType ? icon.component : null}
    </div>
  );
};

InfoBox.defaultProps = {
  rootClassName: null,
  className: null,
  imgClassName: null,
  imgSrc: null,
  title: null,
  text: null,
  contentType: INFORMATION,
  iconType: null,
  withBorder: false,
  withBackground: false,
  withIcon: false,
};

InfoBox.propTypes = {
  rootClassName: string,
  className: string,
  imgClassName: string,
  imgSrc: string,
  contentType: oneOf([INFORMATION, ATTENTION, HELP, SUCCESS]),
  iconType: oneOf([INFORMATION, ATTENTION, HELP, SUCCESS]),
  title: oneOfType([string, array, node]),
  text: oneOfType([string, array, node]),
  withBorder: bool,
  withBackground: bool,
  withIcon: bool,
};

export default InfoBox;
