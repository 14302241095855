import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_SHIPPING_INSURANCE_FEE, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemShippingInsuranceFeeMaybe = props => {
  const { lineItems, intl } = props;

  const shippingInsuranceFeeLineItem = lineItems.find(
    item => item.code === LINE_ITEM_SHIPPING_INSURANCE_FEE && !item.reversal
  );

  return shippingInsuranceFeeLineItem ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        {intl.formatMessage({ id: 'OrderBreakdown.shippingInsuranceFee' })}
      </span>
      <span className={css.itemValue}>
        {formatMoney(intl, shippingInsuranceFeeLineItem.lineTotal)}
      </span>
    </div>
  ) : null;
};

LineItemShippingInsuranceFeeMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemShippingInsuranceFeeMaybe;
