import { storableError } from '../../util/errors';
import { currentUserShowSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { denormalisedResponseEntities } from '../../util/data';

// ================ Action types ================ //

export const SAVE_SHIPPING_DETAILS_REQUEST =
  'app/ShippingDetailsPage/SAVE_SHIPPING_DETAILS_REQUEST';
export const SAVE_SHIPPING_DETAILS_SUCCESS =
  'app/ShippingDetailsPage/SAVE_SHIPPING_DETAILS_SUCCESS';
export const SAVE_SHIPPING_DETAILS_ERROR = 'app/ShippingDetailsPage/SAVE_SHIPPING_DETAILS_ERROR';

// ================ Reducer ================ //

const initialState = {
  saveShippingDetailsInProgress: false,
  shippingDetailsChanged: false,
  saveShippingDetailsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_SHIPPING_DETAILS_REQUEST:
      return {
        ...state,
        saveShippingDetailsInProgress: true,
        saveShippingDetailsError: null,
        shippingDetailsChanged: false,
      };
    case SAVE_SHIPPING_DETAILS_SUCCESS:
      return {
        ...state,
        saveShippingDetailsInProgress: false,
        saveShippingDetailsError: null,
        shippingDetailsChanged: true,
      };

    case SAVE_SHIPPING_DETAILS_ERROR:
      return { ...state, saveShippingDetailsInProgress: false, saveShippingDetailsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveShippingDetailsRequest = () => ({ type: SAVE_SHIPPING_DETAILS_REQUEST });
export const saveShippingDetailsSuccess = () => ({ type: SAVE_SHIPPING_DETAILS_SUCCESS });
export const saveShippingDetailsError = error => ({
  type: SAVE_SHIPPING_DETAILS_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

/**
 * Make a shipping details update request to the API and return the current user.
 */
const requestSaveShippingDetails = params => (dispatch, getState, sdk) => {
  const shippingDetails = params.shippingDetails;

  return sdk.currentUser
    .updateProfile(
      { protectedData: { shippingDetails } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveShippingDetailsError(storableError(e)));
      // pass the same error so that the SAVE_SHIPPING_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/**
 * Update contact details, actions depend on which data has changed
 */
export const saveShippingDetails = params => (dispatch, getState, sdk) => {
  dispatch(saveShippingDetailsRequest());

  return (
    dispatch(requestSaveShippingDetails(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveShippingDetailsSuccess());
      })
      // error action dispatched in requestSaveShippingDetails
      .catch(e => null)
  );
};

export const loadData = () => {
  return fetchCurrentUser();
};
